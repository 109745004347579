
export const pagePosition = [
  { code: 'no_position', label: 'No Position' },
  { code: 'slider', label: 'Slider' },
  { code: 'main', label: 'Main' },
  { code: 'important1', label: 'Important 1' },
  { code: 'important2', label: 'Important 2' },
  { code: 'exclusive', label: 'Exclusive' },
  { code: 'front_page', label: 'Front Page' },

   
  ];
  
