import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {micrositLogo} from '../layouts/dashboard/api/micrositeApi'
import { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session'
import { baseUrl } from 'src/layouts/dashboard/api';
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const [micrositLogo,setMicrositeLogo] = useState('')
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

 

  useEffect(()=>{
    const localData = ReactSession.get('MicrositeInfo');
    const micrositeData = localData.microsite_info;
    const Logo = `${baseUrl}/${micrositeData.entity_logo}`
    if(localData){
      setMicrositeLogo(Logo)
    }

  },[])

  const logo = (
    <Box sx={{ width: 80, height: 80, ...sx }}>
      <img src={micrositLogo} alt=""/>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
