// ----------------------------------------------------------------------
export const categoryPosition = [
    { code: 'no_position', label: 'No Position' },
    { code: 'main_nav ', label: 'Main Menu' },
    { code: 'sub_nav1', label: 'Sub Menu 1' },
    { code: 'sub_nav2', label: 'Sub Menu 2' },
    { code: 'footer_nav ', label: 'Footer Navigation' },
    { code: 'front_page', label: 'Front page' },
   
  ];

