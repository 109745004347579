import React, { useState } from 'react';
import '../src/assets/css/allCss.css'
import { Switch, Route } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import axiosInstance from './utils/axios';
import { micrositeUrl, userProfileUrl } from './layouts/dashboard/api';




// ----------------------------------------------------------------------
const url = window.location.href;
const urlParams = new URLSearchParams(url);
const token = urlParams.get('t');
const uuid = urlParams.get('u');


export default function App() {

  const [login,setLogin] = useState()

  ReactSession.setStoreType("localStorage");
  React.useEffect(() => {
    if(!ReactSession.get('UserInfo')){
      axios.get(userProfileUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
       
        ReactSession.set("UserInfo", response.data);
        ReactSession.set("Token", token);
        ReactSession.set("Uuid", uuid);
      })
    }

    if (!ReactSession.get('MicrositeInfo')) {
      axios.post(micrositeUrl, {
        uuid: `${uuid}`
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        ReactSession.set("MicrositeInfo", response.data);
        window.location.reload();
      }).catch((err) => {
       
        ReactSession.set("MicrositeInfo", '');
        // window.location.href = "https://www.icircles.app";
      })
    }
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}