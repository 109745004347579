import PropTypes from 'prop-types';
import { ReactSession } from 'react-client-session';
import { Container, Alert, AlertTitle } from '@mui/material';

// ----------------------------------------------------------------------


export default function RoleBasedGuard({ accessibleRoles, children }) {
  const MicrositeInfo = ReactSession.get("MicrositeInfo");

  if (MicrositeInfo.status !== 'success') {
    return (
      <Container>
        <Alert severity="warning">
          <AlertTitle>Wait to gain access</AlertTitle>
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
