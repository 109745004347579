const localToken = "Bearer 115|u74mrXTKm6OQJesRuAbEnQuAO7uXDlpG5jtrRHL2";

const baseUrl = "https://icircles.app";

const getAllMicrositeUrl = `${baseUrl}/api/mymicrosites/microsites`;
const micrositeUpdateUrl =`${baseUrl}/api/mymicrosites/microsite`
const getAllPagesUrl = `${baseUrl}/api/cms/pages`


const micrositeUrl = `${baseUrl}/api/mymicrosites/micrositepermission`;
const userProfileUrl =`${baseUrl}/api/auth/user`;

const pageAllCategoriesUrl = `${baseUrl}/api/cms/pagecategories`;
const pageDeleteCategoryUrl = `${baseUrl}/api/cms/pagecategories`;
const pageStoreCategoryUrl = `${baseUrl}/api/cms/pagecategories`;
const parentPageTitlesUrl = `${baseUrl}/api/cms/pages`;
const createNewPageUrl = `${baseUrl}/api/cms/pages`;
const allNewPageUrl = `${baseUrl}/api/cms/pages`;
const deletePageUrl = `${baseUrl}/api/cms/pages`;

const getAllAuthorsList = `${baseUrl}/api/littlemag/authors`;
const createNewAuthorUrl = `${baseUrl}/api/littlemag/authors`;
const deleteAuthorUrl = `${baseUrl}/api/littlemag/authors`;
const updateAuthorUrl = `${baseUrl}/api/littlemag/authors`;

export {
  micrositeUrl,
  userProfileUrl,
  localToken,
  deletePageUrl,
  baseUrl,
  pageAllCategoriesUrl,
  createNewPageUrl,
  pageStoreCategoryUrl,
  pageDeleteCategoryUrl,
  allNewPageUrl,
  parentPageTitlesUrl,
  getAllAuthorsList,
  createNewAuthorUrl,
  deleteAuthorUrl,
  updateAuthorUrl,
  getAllMicrositeUrl,
  micrositeUpdateUrl,
  getAllPagesUrl

};
