import { Suspense, lazy } from 'react';
import { ReactSession } from 'react-client-session';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts 
import DashboardLayout from '../layouts/dashboard';
// guards 
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <RoleBasedGuard>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'cms',
          children: [
            { element: <Navigate to="/dashboard/cms/list" replace />, index: true },
            { path: 'list', element: <WebsiteContentPosts /> },
            { path: 'new', element: <WebsiteContentNewPost /> },
            { path: ':name/edit', element: <WebsiteContentPostEdit /> },
          ],
        },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <WebsiteContentCategoriess /> },
            { path: 'new', element: <WebsiteContentNewCategoryForm /> },
            { path: ':uuid/edit', element: <WebsiteContentEditCategory /> },
          ],
        },
        {
          path: 'microsite',
          children: [
            { element: <Navigate to="/dashboard/microsite/list" replace />, index: true },
            { path: 'list', element: <WebsiteContentMicrosite /> },
            { path: 'new', element: <WebsiteContentNewMicrosite /> },
            { path: 'edit', element: <WebsiteContentEditMicrosite /> },
          ],
        },
        {
          path: 'authors',
          children: [
            { element: <Navigate to="/dashboard/authors/list" replace />, index: true },
            { path: 'list', element: <WebsiteContentAuthors /> },
            { path: 'new', element: <WebsiteContentNewAuthorsForm /> },
            { path: ':uuid/edit', element: <WebsiteContentEditAuthors /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },
    { path: '*', element: <Navigate to="/dashboard" replace /> },
  ]);
}

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// CMS
const WebsiteContentPosts = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentPosts')));
const WebsiteContentNewPost = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentNewPost')));
const WebsiteContentPostEdit = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentPostEdit')));

// Categories
const WebsiteContentCategoriess = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentCategoriess')));
const WebsiteContentNewCategoryForm = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentNewCategory')));
const WebsiteContentEditCategory = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentEditCategory')));

// Authors
const WebsiteContentAuthors = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentAuthors')));
const WebsiteContentNewAuthorsForm = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentNewAuthors')));
const WebsiteContentEditAuthors = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentEditAuthors')));

// Microsites
const WebsiteContentMicrosite = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentMicrosites')));
const WebsiteContentNewMicrosite = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentNewMicrosite')));
const WebsiteContentEditMicrosite = Loadable(lazy(() => import('../pages/dashboard/WebsiteContentEditMicrosite')));


// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

